import React from 'react'
import Box from '@mui/material/Box';

import "./statementcard.css"
import CircularPercent from './CircularPercent'
import { Skeleton } from '@mui/material';

function StatementCard({statement,loading,title, percent, color, textColor,skelColor}) {
  return (
    <div className="statementCard">
      { !loading ? (<><div className="statementcard_left">
      <Box component="section" sx={{ p:2 }}> 
        <h4>{title}</h4>
        <p> {statement.nbtx} operations engagées</p>
      </Box>
      </div>
      <div className="statement_right">
        <CircularPercent percent={Math.round(statement.nbc / statement.nbtx *100) || 0} color={color} />
        <div className="text_win">
          <Box component="section" sx={{ color: textColor }}>
            <span className="nomber_win">{statement.nbc}</span>
            <p className="text_right">transactions terminées avec success</p>
            <h5>{statement.mg} Xaf de marge</h5>
          </Box>
        </div>
      </div></>) : <Skel skelColor={skelColor} />}
    </div>
  );
}

const Skel = ({skelColor}) => {
  return (
      <div >
          <Skeleton sx={{ bgcolor: skelColor}} variant="circular" width={125} height={125} />
          <span className='mar'></span>
          <Skeleton variant="text"  sx={{ fontSize: '1rem' }} />
      </div>
  )
}

export default StatementCard
