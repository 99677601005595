import React, { useEffect, useState } from 'react'

import "./statement2.css"
import StatementCard from './statementcard/StatementCard'
import { periods, defaultPeriod, getToApi } from '../../functions/utilsFunctions'
import StatementGraph from '../statement/StatementGraph'
import { Skeleton } from '@mui/material'

function Statement2() {
const [state, setState] = useState()
const [loading, setLoading] = useState(true)
const [bareOperations, setBareOperations] = useState({ send: [], buy: [], sell: [] })
const [statement, setStatement] = useState({ send: { ch: 0, mg: 0 }, buy: { ch: 0, mg: 0 }, sell: { ch: 0, mg: 0 } })
const [total,setTotal] = useState({mg:0, ch:0})

useEffect(() => {
    getBareData().then((data) => {
        console.log("the bare operations", data)
        getData(data, defaultPeriod)
        setBareOperations(data);
        setLoading(false);
    })

  
}, [])

const filterByDate = (data, period) => {
    return data.filter(item => item.created_at > period)
}

const getTransactions = async (route, period = defaultPeriod) => {
    return await getToApi(route + period)
}

const getBareData = async () => {
    let sendTx = await getTransactions('getsendbydate/:', periods.all)
    let buyTx = await getTransactions('getbuybydate/:', periods.all)
    let sellTx = await getTransactions('getsellbydate/:', periods.all)
    return { send: sendTx, buy: buyTx, sell: sellTx }
}

const getData = (data, period) => {
    console.log("the operations ", data)
    let sendTx = filterByDate(data.send, period)
    let send = parseSend(sendTx)
    let buyTx = filterByDate(data.buy, period)
    let buy = parseBuy(buyTx)
    let sellTx = filterByDate(data.sell, period)
    let sell = parseSell(sellTx)
    // setOperations({ send: sendTx, buy: buyTx, sell: sellTx })
    setStatement({ send: send, buy: buy, sell: sell })
}

const parseSend = (txs) => {
	let nb = 0, chi = 0, cho = 0
	txs.forEach((tx) => {
		if (tx.status === 'completed') {
			nb++
			chi += tx.bch * 655
			cho += tx.amount + 250

		}
	})
	return { nbtx: txs.length, nbc: nb, ch: chi, mg: chi - cho }
}
const parseBuy = (txs) => {
	let nb = 0, chi = 0, cho = 0
	txs.forEach((tx) => {
		if (tx.status === 'complete') {
			nb++
			chi += tx.amount
			cho += tx.crypto

		}
	})
	return { nbtx: txs.length, nbc: nb, ch: chi, mg: chi - cho }

}
const parseSell = (txs) => {
	let nb = 0, chi = 0, cho = 0
	txs.forEach((tx) => {     
		if (tx.status === 'complete') {
			nb++
			cho += tx.amount
			chi += tx.crypto

		}
	})
	return { nbtx: txs.length, nbc: nb, ch: chi, mg: chi - cho }
}

const calculMt = (statement) => {
	setTotal({...total, mg: statement.send.mg + statement.buy.mg + statement.sell.mg})
}
const calculCht = ({ send, buy, sell }) => {
    setTotal({...total, ch: statement.send.ch + statement.buy.ch + statement.sell.ch})
}

const changePeriod = (period) => {
    getData(bareOperations, period)
}

console.log("le statement", statement)

  return (
    <div className="resum">
      <h2 className="recap_title">Recapitulatif des transactions</h2>
      <div className="bilans">
        <div>
          <h1>Bilans des services</h1>
          <div className="provid">
            Periode:{" "}
            <Period
              changePeriod={changePeriod}
              state={state}
              setState={setState}
            />
          </div>
        </div>
      </div>
      <div className="group_card">
        <StatementCard
          statement={statement.buy}
          loading={loading}
          skelColor="secondary.main"
          title="Operation Vente des Cryptos"
          percent={78}
          color="secondary"
          textColor="#9c27b0"
        />
        <StatementCard
          statement={statement.sell}
          loading={loading}
          skelColor="warning.main"
          title="Operation d'achats des Cryptos"
          percent={18}
          color="warning"
          textColor="#ed6c02"
        />
        <StatementCard
          statement={statement.send}
          loading={loading}
          skelColor="success.main"
          title="Operation transfert d'argent"
          percent={58}
          color="success"
          textColor="#2e7d32"
        />
        {/* <StatementCard statement={statement} loading={loading} skelColor="primary.main" title="recapitulatif global" percent={60} color="primary" textColor="#1976d2" /> */}
      </div>

      <div className="graph shadows">
        <h2 style={{padding: "10px 0 5px 18px"}}>Evolution de l'activité dans le temps</h2>
        {loading ? (
          <div className="chiffre">
            <Skeleton
              animation="wave"
              variant="text"
              height={200}
              width={400}
            />
          </div>
        ) : (
          <div className="statgraphi">
            <StatementGraph operations={bareOperations} />
          </div>
        )}
      </div>
    </div>
  );
}

const Period = ({ changePeriod, start = "Mensuelle" }) => {
    const [state, setState] = useState(start)
    const ref = React.createRef()

    const toogleList = () => ref.current.classList.toggle('show')
    const selectTime = (label, period) => {
        setTimeout(() => setState(label), 500)
        toogleList()
        changePeriod(period)
    }
    // console.log("les props ", time)

    return (
        <div className="choose-time">
            <div className="dropdown" onClick={toogleList} > <h4>{state}</h4> <span /> </div>
            <div ref={ref} className="countries-list">
                <div className="time" onClick={() => selectTime("Journaliere", periods.day)}  >
                    <h4>Journaliere</h4>
                </div>
                <div className="time" onClick={() => selectTime("Hebdomaire", periods.week)} >
                    <h4>Hebdomaire</h4>
                </div>
                <div className="time" onClick={() => selectTime("Mensuelle", periods.month)} >
                    <h4>Mensuelle</h4>
                </div>
                <div className="time" onClick={() => selectTime("Toutes", periods.all)} >
                    <h4>Toutes</h4>
                </div>
            </div>
        </div>
    )
}

export default Statement2


