
import './App.css'
import AppRoot from './components/AppRoot'

function App() {
	return (
		<div className="App">
			<AppRoot />
		</div>
	)
}

export default App;

// TODO: make dashboar graphic ui